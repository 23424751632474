.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-background{
  background-image: url('assets/face.png');
  background-size: cover;
}

.Section-background{
  background-color: white;
  background-image: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.amplify-storagemanager.amplify-storagemanager__previewer{
  display: none;
}

.boldHeader {
  font-weight: 790;
  color: black;
}
